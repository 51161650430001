<template>
  <section>
    <div id="pdf-content">
      <section id="surat">
        <!-- <div class="row mb-2">
          <div class="col-11 offset-1">
            <div class="row" id="kop">
              <div class="col-2 bb">
                <img src="@/assets/logo/logo_surat.png" alt="" />
              </div>
              <div class="col-10 bb">
                <h3 id="judul">
                  PEMERINTAH KABUPATEN BANDUNG <br />
                  DINAS PEKERJAAN UMUM DAN TATA RUANG <br />
                  UPTD PENERANGAN JALAN UMUM <br />
                </h3>
                <p id="alamat">
                  Jl. RAYA SOREANG-BANJARAN KM. 3 SOREANG TELP. 5892773-5892580 –
                  SOREANG
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row" id="halaman">
          <div class="col">
            <p
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                overflow: hidden;
                text-decoration: underline;
              "
            >
              REKAPITULASI KEGIATAN PEMELIHARAAN PJU TAHUN {{ params.tahun }}
            </p>

            <div id="content">
              <center v-if="isAssignmentLoading">
                <br />
                <br />
                <vue-loading></vue-loading>
              </center>
              <br />
              <template v-if="!isAssignmentLoading">
                <table
                  class="table table-sm table-borderless"
                  style="margin-top: -10px"
                >
                  <thead>
                    <tr class="text-center">
                      <th class="border-set">No.</th>
                      <th class="border-set">Bulan</th>
                      <th class="border-set">Jumlah Kegiatan</th>
                      <th class="border-set">Jumlah Lokasi</th>
                      <th class="border-set">Jumlah TC</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="text-center" v-for="(value, key, index) in data" :key="key" v-if="
                          key !== 'Jumlah Total Assignment' &&
                          key !== 'Jumlah Total Complaint' &&
                          key !== 'Jumlah Total Tc'"
                      >
                        <td class="border-set">{{ index + 1 }}</td>
                        <td class="border-set">{{ translateMonthToBahasa(key) }}</td>
                        <td class="border-set">{{ value['jumlah assignment'] }}</td>
                        <td class="border-set">{{ value['jumlah complaint'] }}</td>
                        <td class="border-set">{{ value['jumlah tc'] }}</td>
                    </tr>
                    <tr class="text-center">
                      <td class="border-set" colspan="2" ><b>Jumlah Total</b></td>
                      <td class="border-set">{{ data['Jumlah Total Assignment'] }}</td>
                      <td class="border-set">{{ data['Jumlah Total Complaint'] }}</td>
                      <td class="border-set">{{ data['Jumlah Total Tc'] }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
        </div>
      </section>
      <!-- Modal -->
      <CModal 
      size="sm"
      :title="'Pilih Format Print'"
      centered
      :show.sync="showModal"
      :closeOnBackdrop="false"
      >
        <template>
          <div class="modal-body">
            <p>Silahkan pilih format print</p>
          </div>
        </template>
        <template #footer>
          <div class="modal-footer">
            <CButton @click="exportAsPDF" class="btn btn-primary">PDF</CButton>
            <CButton @click="exportAsExcel" class="btn btn-primary">Excel</CButton>
            <CButton @click="closeModal" class="btn btn-secondary mr-3">Close</CButton>
          </div>
        </template>
      </CModal>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      noIndex : 1,
      data: null,
      isAssignmentLoading: true,
      isLoading: true,
      params: {
        tahun: null,
      },
      showModal: false,
    };
  },
  methods: {
    translateMonthToBahasa(month) {
      const monthTranslations = {
        'January': 'Januari',
        'February': 'Februari',
        'March': 'Maret',
        'April': 'April',
        'May': 'Mei',
        'June': 'Juni',
        'July': 'Juli',
        'August': 'Agustus',
        'September': 'September',
        'October': 'Oktober',
        'November': 'November',
        'December': 'Desember',
      };
      return monthTranslations[month] || month;
    },
    getData() {
      var loading = this.$loading.show();
      this.$store.dispatch("report/getReportPemeliharaan", this.params).then((resp) => {
        loading.hide();
        this.data = resp.data;
        // console.log(this.data);
        // setTimeout(() => {
          this.isAssignmentLoading = false;
          this.isLoading = false;
          this.showModal = true;
        // }, 2000);
        // setTimeout(function () {
        //   window.print();
        // }, 5000);
      }).catch(e => {
        this.$toast.error(e);
        loading.hide();
      });
    },
    closeModal() {
      this.showModal = false;
      window.close();
    },
    exportAsPDF() {
      this.showModal = false;
      setTimeout(function () {
          window.print();
      }, 5000);
    },
    exportAsExcel() {
      const data = this.data;

      if (!data || Object.keys(data).length === 0) {
        return;
      }

      const title = this.params.tahun;
      const titleRow = [`REKAPITULASI KEGIATAN PEMELIHARAAN PJU TAHUN ${title}`];
      let csvContent = titleRow.join(',') + '\n';
      csvContent += 'No.,Bulan,Jumlah Kegiatan,Jumlah Lokasi,Jumlah TC\n';

      let index = 0;
      let totalAssignment = 0;
      let totalComplaint = 0;
      let totalTc = 0;
      for (const key in data) {
        if (
          key !== 'Jumlah Total Assignment' &&
          key !== 'Jumlah Total Complaint' &&
          key !== 'Jumlah Total Tc'
        ) {
          index++;
          const assignment = data[key]['jumlah assignment'] || 0;
          const complaint = data[key]['jumlah complaint'] || 0;
          const tc = data[key]['jumlah tc'] || 0;

          totalAssignment += assignment;
          totalComplaint += complaint;
          totalTc += tc;

          const translatedMonth = this.translateMonthToBahasa(key);
          csvContent += `${index},${translatedMonth},${assignment},${complaint},${tc}\n`;
        }
      }

      csvContent += `"Jumlah Total","",${totalAssignment},${totalComplaint},${totalTc}\n`;

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', `data:text/csv;charset=utf-8,${encodedUri}`);
      link.setAttribute('download', 'RekapitulasiKegiatanPemeliharaanTahunan.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.showModal = false;
    },
  },
  created() {
    this.params.tahun = this.$route.query.tahun;
  },
  mounted() {
    this.getData()
  },
};
</script>
  
  
<style scoped>
  body {
    font-size: 9px;
  }
  
  #judul {
    color: black;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
  }
  #alamat {
    color: black;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
  }
  #kop {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 38px;
    padding-right: 19px;
    /* padding-bottom: 28px; */
    border-bottom: 11px double black;
  }
  #halaman {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 113px;
    padding-right: 76px;
    padding-bottom: 28px;
    font-family: "Times New Roman", Times, serif;
  }
  img {
    color: black;
    height: 110px;
  }
  #content {
    color: black;
    text-align: justify;
    font-size: 20px;
  }
  .border-set {
    /* font-size: 20px; */
    border: 2px solid #000 !important;
  }
  p {
    color: black;
    margin: 5px;
  }
</style>

<style>
#pdf-content {
  width: calc(29.7cm - 2cm * 2);
  /* margin: 28pt 32pt 28pt 32pt;
        word-spacing: 1pt;
        word-break: page-break; */
  color: #000 !important;
}
#content-table {
  font-size: 8pt;
  /* border-top: 1pt solid black;
  border-left: 1pt solid black; */
  border-collapse: collapse;
}
#content-table th,
#content-table td {
  /* border-right: 1px solid black;
  border-bottom: 1px solid black; */
  border: 1px solid black;
  padding-left: 3pt;
  padding-right: 3pt;
}
#footer {
  font-size: 8pt;
}
table {
  white-space: normal !important;
}
</style>